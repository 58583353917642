export const API_URL = 'https://api.bluesync.bluemoonsolutions.co.uk/v1';
export const WEB_EMAIL = 'support@crushedice.biz';
export const RECAPTCHA_SITE_KEY = '6LdOb68UAAAAAIgrC5vTu1mqvMCeW2QmkfMBS0EN';
export const MAX_UPLOAD_LIMIT_SIZE = '1.5GB';
export const IN_DEV = false;
export const CLIENT_VERSION = '2.4';
export const SHOW_DEV_ALERT = false;
export const CLOUDFRONT_URL = "https://files.bluesync.bluemoonsolutions.co.uk";

export const MS365_CLIENT_ID = 'a8758f9a-13ac-42b7-944f-d2043b9bab23';
export const MS365_REDIRECT_URI = 'https://bluesync.bluemoonsolutions.co.uk/ms365/tenants';

export const SAGE_CLOUD_API_VERSION = '3.1';
export const SAGE_CLOUD_CLIENT_ID = 'ce1e70e5-24fb-43e0-9fe3-3c03041b7859/6a93bb13-53df-44a3-b814-ee2b51c25277';
export const SAGE_CLOUD_REDIRECT_URI = 'https://bluesync.bluemoonsolutions.co.uk/accounts/sage/auth';